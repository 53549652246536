import axios from 'axios';

export const uploadFiles =
	(files, onSuccess = () => {}, onError = () => {}) =>
	async dispatch => {
		let noOfUploadedFiles = 0;

		for (let file of files) {
			let formData = new FormData();
			formData.append('File', file);
			dispatch({
				type: 'SET_LOADING_TEXT',
				payload: `Uploading ${++noOfUploadedFiles}/${files.length}`,
			});
			try {
				let response = await axios.post(
					'https://volangua-staging.herokuapp.com/upload',
					formData
				);
				console.log(response);
				if (response.statusText === 'OK') {
					dispatch({
						type: 'ADD_FILE',
						payload: {
							url: response.data.data,
							name: response.data.name,
							size: response.data.size,
						},
					});
				}
				onSuccess();
			} catch (err) {
				console.log(err);
				// onError(err.response.status);
			}
		}
		if (noOfUploadedFiles == files.length) {
			dispatch({
				type: 'SET_LOADING_TEXT',
				payload: null,
			});
		}
	};

export const removeImage = idx => {
	return {
		type: 'REMOVE_IMAGE',
		payload: idx,
	};
};

export const startOver = () => {
	return {
		type: 'RESET_FILES',
	};
};
