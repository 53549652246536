import { AUTH_NOTIFICATION, AUTH_SET_LOADING } from "../types";

const initialState = {
  files: [],
  loadingText: null,
};

export default function fileReducer(state = initialState, { type, payload }) {
  switch (type) {
		case 'ADD_FILE':
			return {
				...state,
				files: [...state.files, payload],
			};
		case 'REMOVE_IMAGE':
			return {
				...state,
				files: state.files.filter(
					(_, idx) => Number(idx) !== Number(payload)
				),
			};
		case 'SET_LOADING_TEXT':
			return {
				...state,
				loadingText: payload,
			};
		case 'RESET_FILES':
			return {
				...state,
				files: [],
			};
		default:
			return { ...state };
  }
}
