import Index from '../views/Index';


let routes = [
	{
		path: '/',
		component: Index,
		layout: 'main',
	},
];
export default routes;