import { createRef, useState } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row, Spinner } from 'reactstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
	removeImage,
	startOver,
	uploadFiles,
} from '../store/actions/fileAction';
import JSZip from 'jszip';
import { useEffect } from 'react';

const AdminView = props => {
	const [error, setError] = useState(null);
	let dispatch = useDispatch();
	const dropzoneRef = createRef();
	let [downloadAll, setDownloadAll] = useState(false);
	let [downloading, setDownloading] = useState([]);
	let [forceUpdate, setForceUpdate] = useState([]);
	const [showScroll, setShowScroll] = useState(false);
	let size = 10 * 1024;
	let { files: uploadedFiles, loadingText } = useSelector(
		state => state.file
	);
	const getFilename = (filename, idx = 0) => {
		let temp = filename.split('.');
		temp.pop();
		filename = temp.join('.');
		return filename + (idx == 0 ? '' : ` (${idx})`) + '.png';
	};
	const handleImages = acceptedFiles => {
		let tempSize = uploadedFiles.reduce((prev, curr) => {
			return prev + curr.size / 1024;
		}, 0);
		console.log(tempSize);
		if (tempSize >= size) {
			setError(
				`You are only allowed to convert ${size / 1024} MBs of data`
			);
			window.setTimeout(() => {
				setError(null);
			}, 5000);
			return;
		}
		for (let acceptedFile of acceptedFiles) {
			let splittedName = acceptedFile.name.split('.');
			let ext = splittedName[splittedName.length - 1];
			if (ext !== 'jpeg') {
				setError('Image should be .jpeg');
				window.setTimeout(() => {
					setError(null);
				}, 5000);
				return;
			}
			tempSize += acceptedFile.size / 1024;
		}
		console.log(tempSize);
		if (tempSize >= size) {
			setError(
				`You are only allowed to convert ${size / 1024} MBs of data`
			);
			window.setTimeout(() => {
				setError(null);
			}, 5000);
			return;
		}
		dispatch(
			uploadFiles(
				acceptedFiles,
				() => {},
				code => {
					setError(`Server has an Issue - Code ${code}`);
					setTimeout(() => {
						setError(null);
					}, 5000);
				}
			)
		);
	};
	const onResize = () => {
		setForceUpdate(prevState => !prevState);
	};
	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);
	useEffect(() => {
		if (uploadedFiles.length !== 0) {
			onResize();
		}
	}, [uploadedFiles]);
	const downloadImage = async (url, filename, idx) => {
		filename = getFilename(filename);
		setDownloading(prevState => [...prevState, idx]);
		await fetch(url, {
			method: 'GET',
			headers: {},
		})
			.then(response => {
				response.arrayBuffer().then(function (buffer) {
					const url = window.URL.createObjectURL(new Blob([buffer]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${filename}`); //or any other extension
					document.body.appendChild(link);
					link.click();
				});
			})
			.catch(err => {
				console.log(err);
			});

		setDownloading(prevState => prevState.filter(item => item !== idx));
	};
	const downloadAllImages = async () => {
		setDownloadAll(true);
		let zip = new JSZip();
		let filenames = [];
		for (let uploadedFile of uploadedFiles) {
			let imageBlob = await fetch(
				`data:image/png;base64,${uploadedFile.url}`,
				{
					method: 'GET',
					headers: {},
				}
			).then(async response => response.blob());
			console.log(imageBlob);
			let finalFilename = getFilename(
				uploadedFile.name,
				filenames.filter(
					file => file === getFilename(uploadedFile.name)
				).length
			);
			console.log(finalFilename);
			filenames.push(getFilename(uploadedFile.name));
			let file = new File([imageBlob], finalFilename, {
				type: imageBlob.type,
			});
			console.log(file);
			zip.file(finalFilename, file);
		}
		await zip.generateAsync({ type: 'blob' }).then(content => {
			const url = window.URL.createObjectURL(content);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `AllImages.zip`); //or any other extension
			document.body.appendChild(link);
			link.click();
		});
		setDownloadAll(false);
	};
	const open = () => {
		// Note that the ref is set async,
		// so it might be null at some point
		if (dropzoneRef.current) {
			dropzoneRef.current.open();
		}
	};
	const getHeight = () => {
		let upload0 = document.getElementById('cont-width');
		console.log(upload0);
		if (upload0) {
			let offset = uploadedFiles.length > 3 ? 5 : 2;
			return upload0.offsetWidth / Number(getNoOfCards()) + offset;
		}
		return 0;
	};
	const getNoOfCards = () => {
		console.log(window.innerWidth);
		return window.innerWidth > 991.98
			? 4
			: window.innerWidth > 767.98
			? 3
			: window.innerWidth > 575.98
			? 2
			: 2;
	};
	const Upload = () => (
		<div className='w-100 h-100 m-0 d-flex flex-column align-items-center'>
			<i className='fas fa-upload mb-3 fa-3x'></i>
			Drag images here
		</div>
	);

	useEffect(() => {
		onResize();
	}, []);

	return (
		<>
			<Container fluid className='py-3'>
				<div className='space space--1'>Space1</div>
				<Row className='mt-3'>
					<Col xl='9'>
						<div className='space space--2'>Space2</div>
						<div className='d-md-flex my-2 align-items-center'>
							<h1
								className='m-0 text-center text-md-left'
								style={{ fontSize: '32px', fontWeight: 'Bold' }}
							>
								JPEG to PNG Converter
							</h1>
							<div className='mt-3 mt-md-0 ml-auto d-flex justify-content-end'>
								<Button
									onClick={open}
									color='dark'
									className='ml-auto px-3 mr-3 bg-dark'
									style={{ border: 'none' }}
								>
									Upload Images
								</Button>
								{uploadedFiles.length !== 0 && (
									<>
										<Button
											onClick={() =>
												dispatch(startOver())
											}
											color='dark'
											className='ml-auto px-3 mr-3 bg-danger'
											style={{ border: 'none' }}
										>
											Start Over
										</Button>
										<Button
											onClick={() => downloadAllImages()}
											color='dark'
											className='px-3 bg-primary'
											disabled={downloadAll}
											style={{
												width: 140,
												border: 'none',
											}}
										>
											{downloadAll ? (
												<Spinner size='sm' />
											) : (
												'Download All'
											)}
										</Button>
									</>
								)}
							</div>
						</div>

						<Dropzone
							ref={dropzoneRef}
							noClick
							noKeyboard
							onDrop={acceptedFiles =>
								handleImages(acceptedFiles)
							}
						>
							{({ getRootProps, getInputProps }) => (
								<section
									className={`w-100 dropzone ${
										error != null ? 'dropzone__invalid' : ''
									} mt-3 d-flex align-items-center justify-content-center`}
									style={{
										height: getHeight() * 2 + 'px',
									}}
								>
									{error != null && (
										<div className='dropzone__overlay dropzone__overlay--error d-flex text-center align-items-center justify-content-center'>
											{error}
										</div>
									)}
									{loadingText != null && (
										<div className='dropzone__overlay dropzone__overlay--loading d-flex flex-column text-center align-items-center justify-content-center'>
											<Spinner />
											<span className='mt-4'>
												{loadingText}
											</span>
										</div>
									)}
									<div
										className={`w-100 ${
											uploadedFiles.length === 0
												? 'd-flex align-items-center justify-content-center'
												: ''
										}`}
										style={{ overflow: 'hidden' }}
										{...getRootProps({
											onClick: e => e.stopPropagation(),
										})}
									>
										<input {...getInputProps()} />
										{uploadedFiles.length === 0 ? (
											<Upload />
										) : (
											<div
												style={{
													height:
														getHeight() * 2 + 'px',
													overflowX: 'hidden',
													overflowY:
														uploadedFiles.length >
														getNoOfCards()
															? 'auto'
															: 'hidden',
												}}
												className='p-4'
											>
												<Row className='mt-0'>
													{uploadedFiles.map(
														(file, idx) => (
															<Col
																lg='3'
																md='4'
																sm='6'
																xs='6'
																id={`uploadFile${idx}`}
																className={
																	idx >=
																	getNoOfCards()
																		? 'mt-4'
																		: ''
																}
															>
																<div
																	className='download-card'
																	style={{
																		backgroundImage: `url(data:image/png;base64,${file.url})`,
																	}}
																>
																	<div className='d-flex align-items-start w-100'>
																		<small
																			title={
																				file.name
																			}
																			className='text-white download-card__filename pr-2'
																		>
																			<b className='fw--700'>
																				{
																					file.name
																				}
																			</b>
																		</small>
																		<Button
																			size='sm'
																			color='white'
																			className='flex-shrink-0 bg-white ml-auto'
																			onClick={() =>
																				dispatch(
																					removeImage(
																						idx
																					)
																				)
																			}
																			style={{
																				width: 30,
																				height: 30,
																			}}
																		>
																			<i className='fa fa-times'></i>
																		</Button>
																	</div>
																	<div className='my-auto d-flex justify-content-center'>
																		<h2 className='fw--900 text-white'>
																			PNG
																		</h2>
																	</div>
																	<Button
																		onClick={() =>
																			downloadImage(
																				`data:image/png;base64,${file.url}`,
																				file.name,
																				idx
																			)
																		}
																		block
																		color='white'
																		className='mt-3 bg-white fw--900'
																		size='sm'
																		disabled={downloading.includes(
																			idx
																		)}
																	>
																		{downloading.includes(
																			idx
																		) ? (
																			<Spinner size='sm' />
																		) : (
																			'Download'
																		)}
																	</Button>
																</div>
															</Col>
														)
													)}
												</Row>
											</div>
										)}
									</div>
								</section>
							)}
						</Dropzone>

						<div id='cont-width' className='w-100'></div>
					</Col>
					<br />
					<br />
					<br />
					<Col xl='3' className='mt-5 mt-xl-0'>
						<div className='space space--3'>Space3</div>
					</Col>
				</Row>
				<div className='space space--2 mt-3'>Space4</div>
			</Container>
		</>
	);
};

export default AdminView;
