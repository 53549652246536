// Root Reducer

import { combineReducers } from "redux";
import fileReducer from "./fileReducer";

export let rootReducer = combineReducers({
  file: fileReducer,
});

export default rootReducer;
