import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import CryptoJS from "crypto-js";
import { file } from "jszip";

const saveToLocalStorage = (state) => {
  localStorage.setItem("files", JSON.stringify(state.file.files));
};

const checkLocalStorage = () => {
  const files = JSON.parse(localStorage.getItem("files")) || [];
  return {
    file: {
      ...file,
      files
    }  
  };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(
  rootReducer,
  checkLocalStorage(),
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;
